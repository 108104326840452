import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Kaikorai Bowling Club</h1>
      <div>New Website Coming Soon!</div>

      <div>For all enquiries please contact us at <a href="mailto:kbc1@xtra.co.nz">kbc1@xtra.co.nz</a> or call <a href="tel:034667023">03-466 7023</a></div>
    </div>
  );
}

export default App;
